import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderAnimation";
import About from "../../components/about/AboutAnimation";
import Resume from "../../components/resume/ResumeAnimation";
import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/contact/Contact";
import ContactInfo from "../../components/contact/ContactInfo";
import Map from "../../components/contact/Map";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";
import { datadogRum } from '@datadog/browser-rum';
    
datadogRum.init({
  applicationId: 'f8344ba1-f8ad-45f6-85e7-94c80f64b293',
  clientToken: 'puba89c62f4cd9e78c03fb6b3abeff7c6f0',
  site: 'datadoghq.com',
  service:'phdee',
  env:'prd',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow'
});
  
datadogRum.startSessionReplayRecording();

const HomeOne = () => {
  useDocumentTitle(
    "P.H. Dee | Cincinnati Drag Queen"
  );
  return (
    <div className="main-left theme-dark">
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Slider Section */}

      <About />
      {/* End About Section */}

      <Resume />
      {/* End Resume Section */}

      <section id="gallery" className="section theme-light dark-bg">
        <div className="container">
          <div className="title">
            <h3>Gallery</h3>
          </div>
          <Portfolio />
        </div>
      </section>
      {/* End Portfolio Section */}

      {/* <section id="blog" className="section">
        <div className="container">
          <div className="title">
            <h3>Latest Blog.</h3>
          </div>
          <Blog />
        </div>
      </section>
      End Portfolio Section */}

      <section id="booking" className="section theme-light dark-bg">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-xl-4 m-15px-tb"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <ContactInfo />
            </div>
            {/* End Contact info */}

            {/* <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="contact-form">
                <h4>Contact Form:</h4>
                <Contact />
              </div>
            </div> */}
            {/* End contact form */}

            {/* End Col */}
          </div>
        </div>
      </section>
      {/* End Contact Section */}

      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/* End Contact Section */}
    </div>
  );
};

export default HomeOne;
