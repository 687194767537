import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Bookings</h4>
        <br></br>
        <p>
          Interested in booking P.H. Dee for an event?
        </p>
        <p>Click <a href="#calendar">here</a> to check her availability</p>
        <p>
          To reach out directly, use the email below:
        </p>

        <ul>
          {/* <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              Cincinnati, Ohio
            </span>
          </li> */}
          {/* End li */}

          <li className="media">
            <a href="mailto:booking@phdeedragqueen.com"><i className="icon icon-envelope"></i></a>
            <a href="mailto:booking@phdeedragqueen.com"><span className="media-body">booking@phdeedragqueen.com</span></a>
          </li>
          {/* End li */}

          {/* <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+044 9696 9696 3636</span>
          </li> */}
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
