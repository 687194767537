import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaShoppingCart,
  FaMoneyBillWaveAlt,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/p.h.dee.dragqueen/" },
  { Social: <FaTwitter />, link: "https://twitter.com/phdee__" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/phdee__/" },
  { Social: <FaShoppingCart />, link: "https://phdee.threadless.com/" },
  { Social: <FaMoneyBillWaveAlt />, link: "https://account.venmo.com/u/phdeedragqueen" },
];

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
          {val.Social}
        </a>
      ))}
    </div>
  );
};

export default Social;
