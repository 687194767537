import React from "react";
import Skills from "../skills/Skills";
import FullCalendar from "@fullcalendar/react"
import googleCalendarPlugin from "@fullcalendar/google-calendar"
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from "@fullcalendar/daygrid";
import base64 from 'react-native-base64'

const sliderContent = {
  name: "P.H. Dee",
  designation: "Cincinnati Drag Queen",
  description: `Co-show Director at The Birdcage`,
  btnText: "About P.H. Dee",
  btnText2: "Booking Info",
};

const Resume = () => {
  const calendar = base64.decode("QUl6YVN5RGxTTGVHQlRHanNIVVc0YWNrZE5jUHktcDgyRnlwcjhv");
  const calendarid = base64.decode("ZG05MnJ1NTBsZmV2MzN1Nm9sdDI5OWMxcm9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ==");
  return (
<>
<section id="calendar" className="section theme-light dark-bg" >
        <div className="container">
          <div className="title">
            <h4>Upcoming Events for P.H. Dee</h4>
          </div>
 <div>
          <div className="feature-box-02" data-aos="fade-right" data-aos-duration="1200">
          <h1 align="right"><a className="px-btn px-btn-white" href="https://calendar.google.com/calendar/ical/dm92ru50lfev33u6olt299c1ro%40group.calendar.google.com/public/basic.ics">Add events to your Calendar</a></h1>
            <div className="calendar" data-aos="fade-right" data-aos-duration="1200">
            <FullCalendar
      plugins={[listPlugin, googleCalendarPlugin]}
      initialView="listYear"

      googleCalendarApiKey={calendar}
      events={{
        googleCalendarId:
          "dm92ru50lfev33u6olt299c1ro@group.calendar.google.com",
      }}

      displayEventTime={true}
      eventColor={"#751675"}
      listDayFormat={{ // will produce something like "Tuesday, September 18, 2018"
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
      }}
      listDaySideFormat={false}
      height={600}
      contentHeight={600}
      expandRows={true}
      validRange={{
        start: new Date().toISOString().split("T")[0]
      }}


    />
    {/* <a className="px-btn px-btn-white" href="#booking">{sliderContent.btnText2}</a> */}
            </div>
          </div>
  </div>
  </div>
  
</section>
</>
  );
};

export default Resume;
