import React from "react";

const ServiceContent = [
  {
    icon: "test",
    title: "Bar Events",
    descriptions: `Want to diversify the events at your bar? Show the LGBTQ+ community your allyship and host a drag show. 
    Whether it’s a trivia night, a one-woman show, or a full production, contact P.H. Dee for information on what it takes to make it happen. 
    She will guide you through everything you need to know to coordinate a drag show.`,
    delayAnimation: "",
  },
  {
    icon: "icon-briefcase",
    title: "Private Parties",
    descriptions: `Spice up your party with a drag queen! 
    P.H. Dee has performed at a wide variety of private events ranging from pool parties, to birthday parties, to childrens’ story hours, to wedding receptions. 
    She will customize the experience to your vision, and your party will go down in HERstory. Ask her how!`,
    delayAnimation: "200",
  },
];
const sliderContent = {
  name: "P.H. Dee",
  designation: "Cincinnati Drag Queen",
  description: `Co-show director The Birdcage`,
  btnText: "About P.H. Dee",
  btnText2: "Contact P.H. Dee",
};
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              
              <div className="feature-content">
                <br></br><br></br>
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
      <center><a
                    className="px-btn px-btn-white"
                    href="#booking"
                  >
                    {sliderContent.btnText2}
                  </a>
                  </center>
    </>
  );
}
