import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import Services from "../service/ServiceAnimation";
import Awards from "../award/AwardsAnimation";

const About = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me.jpg?" alt="about image" />
                  </div>

                  <Social />

                  {/* End social icon */}
                </div>
                {/* End img */}
                {/* <div className="info">
                  <p>Cincinnati Drag Queen</p>
                  <h3>P.H. Dee</h3>
                </div> */}
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}
              <div className="about-info">
                <div className="title">
                  <h3>About P.H. Dee</h3>
                </div>
                <div className="about-text">
                  <p>
                  A Cincinnati-based drag queen, P.H. Dee began her career in October 2018. 
                  Since then, she has established herself as one of the most in-demand drag performers in the city. 
                  </p>
                  <p>
                  Within her first ten months as a performer, she won two pageant titles 
                  (Miss Red & White 2019 and Miss Queen City 2019) and an Outstanding Newcomer award with the Imperial Sovereign Queen City Court of the Buckeye Empire. 
                  She also won a residency competition at The Cabaret (Over-the-Rhine) in June 2019, which led to a recurring guest and hostess role through December 2020. 
                  </p>
                  <p>
                  {/* Most recently, Dee has found a home at e|19 Lounge, Bar, and Discothèque (Over-the-Rhine), where she serves as co-entertainment director of Gay Church, a Sunday Funday drag show that she coordinates with Molly Mormen.  */}
                  P.H. Dee serves as one of the entertainment directors at The Birdcage (Central Business District), where she books a monthly show.
                  </p>
                  <p>
                  In addition to these roles, she also books private events such as birthday parties and wedding receptions, 
                  and she makes recurring appearances at a variety of venues throughout the city, including Bar 1868 (Oxford), Branch (East Walnut Hills), Esoteric Brewing (Walnut Hills), and Main Event (Downtown).
                  </p>
                </div>
                <div className="info-list">
                  <div className="row">
                    {/* <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Home: </label>
                          <span>Cincinnati, Ohio</span>
                        </li>
                        <li>
                          <label>Age: </label>
                          <span>26 years</span>
                        </li>
                        <li>
                          <label>Address: </label>
                          <span>San Francisco</span>
                        </li>
                        <li>
                          <label>Contact Me: </label>
                          <a href="mailto:booking@phdeedragqueen.com"><span className="media-body">booking@phdeedragqueen.com</span></a> 
                        </li>
                      </ul>
                    </div> */}
                    {/* <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Phone: </label>
                          <span>(+38) 469 2344 2364</span>
                        </li>
                        <li>
                          <label>Email: </label>
                          <span>info@domainname.com</span>
                        </li>
                        <li>
                          <label>Skype: </label>
                          <span>nairob.40</span>
                        </li>
                        <li>
                          <label>Freelance: </label>
                          <span>Available</span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}
          <div className="title">
            <h3>Event Types</h3>
          </div>

          <Services />

          {/* End .row */}

          {/* End separated */}

          {/* <div className="title">
            <h3>Awards.</h3>
          </div>

          <Awards /> */}
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Merchandise</h3>
          </div>
          <Testimonials />
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default About;
