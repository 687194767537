import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "team-1",
      desc: `  Lorem Ipsum is simply dummy text of the printing and
      typesetting industry. Lorem Ipsum has been the industry's
      standard dummy text ever since the 1500s.`,
      reviewerName: "Nancy Byers",
      designation: "CEO at ib-themes",
      delayAnimation: "",
    },
  ];

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
          className="col-md-6 col-lg-4 my-3"
          key={i}
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
        >
          <div className="feature-box-merch">
            
            <div className="feature-content">
              <a href="https://phdee.threadless.com/"><img src="img/testimonial/merch.png" alt="alternatetext" width='50%' height='50%' class="center" target="_blank"/>
              <br></br>
              <h5><center>Click here to view P.H. Dee's Merch Store</center></h5></a>
            </div>
          </div>
          {/* End .feature-box-01 */}
        </div>
        ))}
      </Slider>
    </div>
  );
}
