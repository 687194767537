import React from "react";
import TextLoop from "react-text-loop";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaMoneyBillWaveAlt,
} from "react-icons/fa";

const conctInfo = {
  phone: "",
  email: "dee@phdeedragqueen.com",
};

const sliderContent = {
  name: "P.H. Dee",
  designation: "Cincinnati Drag Queen",
  description: `Co-show Director at The Birdcage`,
  btnText: "About P.H. Dee",
  btnText2: "Booking Info",
};

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/p.h.dee.dragqueen/" },
  { Social: <FaTwitter />, link: "https://twitter.com/phdee__" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/phdee__/" },
  { Social: <FaMoneyBillWaveAlt />, link: "venmo://paycharge?txn=pay&recipients=phdeedragqueen" },
];

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
          {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* <div className="hb-lang">
            <ul className="nav">
              <li className="active">
                <a href="#">EN</a>
              </li>
              <li>
                <a href="#">FR</a>
              </li>
            </ul>
          </div> */}
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  <br></br><p></p>
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead">Cincinnati Drag Queen</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="#about"
                  >
                    {sliderContent.btnText}
                  </a>
                  <a>{" "}</a>
                  <a
                    className="px-btn px-btn-white"
                    href="#booking"
                  >
                    {sliderContent.btnText2}
                  </a>
                  <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                  <a
                    className="px-btn custom-arrow2"
                    href="#booking"
                  > 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/home-banner.jpg"
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
